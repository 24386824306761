import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix, graphql } from 'gatsby';
import { Layout, Header } from 'components';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import config from '../../config/Theme';

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 4rem);
  flex-direction: row;

  .iframe {
    width: calc(100% - 320px);
    border: none;
    margin: 0;
  }
`;

const SidebarWrapper = styled.div`
  width: 320px;
  padding: 1em;
  border-right: 1px solid #ccc;
  overflow: auto;

  h3 {
    font-size: 1.2rem;
  }

  ul {
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 0.25em;
      padding-bottom: 0.3em;
      overflow: hidden;
      border-bottom: 1px solid ${config.colors.grey.ultraLight};

      a {
        background: none;
        border: none;
        padding: 0;
        padding-right: 1rem;
        text-align: left;
        width: calc(100% - 1rem);
        color: ${config.colors.primary};
        cursor: pointer;
        outline: none;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.8rem;
          white-space: nowrap;
        }
      }

      ::after {
        content: '>';
        color: black;
        float: right;
      }
    }
  }
`;

const BannerLink = props => {
  const handleClick = () => {
    props.onClick(props);
  };
  const { name } = props;
  return (
    <li>
      <a role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleClick}>
        <span>{name}</span>
      </a>
    </li>
  );
};

BannerLink.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class Banner extends React.Component {
  constructor(props) {
    super(props);
    const { fields, frontmatter } = props.data.markdownRemark;
    const { slug } = fields;
    console.log(slug);
    const { banners } = frontmatter;
    const links = banners.map(x => ({
      title: x.name,
      url: x.src,
    }));

    this.state = {
      brand: frontmatter.brand,
      banners: links,
      basePath: `/projects/${kebabCase(frontmatter.brand)}/${kebabCase(frontmatter.title)}/`,
      selectedBanner: links[0],
    };
  }

  updateBanner(banner) {
    const { banners } = this.state;
    this.setState({
      selectedBanner: banners.find(k => k === banner),
    });
  }

  render() {
    const { banners, brand, basePath, selectedBanner } = this.state;

    return (
      <Layout>
        <Header brandLogo={brand} />
        <MainWrapper>
          <SidebarWrapper>
            <h3>Select a banner:</h3>
            <ul>
              {banners.map(banner => (
                <BannerLink
                  key={kebabCase(banner.title)}
                  name={banner.title}
                  onClick={() => this.updateBanner(banner)}
                />
              ))}
            </ul>
          </SidebarWrapper>
          <iframe className="iframe" title="banners" src={withPrefix(`${basePath}${selectedBanner.url}/index.html`)} />
        </MainWrapper>
      </Layout>
    );
  }
}

export default Banner;

Banner.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
};

export const bannerQuery = graphql`
  query bannerQueryBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        brand
        title
        banners {
          name
          src
          width
          height
        }
      }
      fields {
        slug
        template
      }
    }
  }
`;
